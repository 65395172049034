import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "BoxScore.PreGame",
  code: "BoxScore\.PreGame",
  parent: "BoxScore"
};
export const Wrapper = ({
  children
}) => <div className="w-[375px]">{children}</div>;
export const _frontmatter = {
  "menuLabel": "Pre-Game",
  "sortOrder": 2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`BoxScore / Pre-Game`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-boxscore--pre-game-horizontal" wrapper={Wrapper} wrapperCond={["PreGame Horizontal", "PreGame Vertical", "PreGame Skeleton"]} mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { BoxScore } from "@caesars-digital/caesars-ui";

const example = () => <BoxScore.PreGame />
`}</code></pre>
    <p>{`The Box Score Pre-Game component can be used in isolation, but is a part of the `}<inlineCode parentName="p">{`BoxScore`}</inlineCode>{` component.  There is a horizontal and vertical variant of this, which causes the `}<inlineCode parentName="p">{`Competitor`}</inlineCode>{` components to either be side diplayed with the time/date details in the middle, or stacked vertically.  `}</p>
    <p>{`It's recommended that the `}<inlineCode parentName="p">{`vertical`}</inlineCode>{` variant is used for non-team matches, such as tennis. Events with two teams as the competitors should use the `}<inlineCode parentName="p">{`horizontal`}</inlineCode>{` version.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Countdown`}</inlineCode>{` will display the remaining days and time until the event starts. You can turn it on or off as per your convenience. Similarly, you can toggle the live stream caption.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10">
  <span className="text-red-600">*</span> indicates required.
    </div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
   The BoxScore component is easily perceivable by all users, including those with visual impairments. The component uses clear and descriptive labels for all elements. It is also designed to be accessible to screen readers so that visually impaired users can access the match details. The component also has sufficient contrast between text and background colors, ensuring that it is readable.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    This component is operable by all users, including those with physical impairments or using assistive technology. The component is easily navigable using keyboard controls or screen readers.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The Box Score's purpose is clear to users, with labels that are easy to understand and descriptive. Users can easily determine when the match is starting, who the teams are and whether or not a live stream is available. The match details are also clearly labeled and accessible to screen readers.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Box Score is compatible with assistive technologies and functions consistently across different browsers and platforms.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      